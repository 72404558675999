import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

import { useLocalize } from "engine/pageContext";
import { Helmet } from "react-helmet";

const Home = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { frontmatter: { tags: { in: ["land"] } } }) {
        edges {
          node {
            id
            frontmatter {
              title_sm
              title
              langKey
              image {
                publicURL
              }
              cta {
                label
                url
              }
            }
          }
        }
      }
    }
  `);
  const { lang, slug } = useLocalize();
  const edges = data.allMarkdownRemark.edges.filter(
    (x) => x.node.frontmatter.langKey === lang
  );

  return (
    <div
      className="owl-carousel owl-carousel-light owl-carousel-light-init-fadeIn owl-theme manual dots-inside dots-horizontal-center show-dots-hover show-dots-xs nav-style-1 nav-arrows-thin nav-inside nav-inside-plus nav-dark nav-lg nav-font-size-lg show-nav-hover mb-0"
      data-plugin-options="{'autoplay': true, 'autoplayTimeout': 7000}"
      data-dynamic-height="['calc(100vh - 318px)','calc(100vh - 318px)','calc(100vh - 161px)','calc(100vh - 161px)','calc(100vh - 161px)']"
      style={{ height: "calc(100vh - 318px)" }}
    >
      <Helmet>
        {edges.map(({ node }) => {
          return (
            <link
              key={`_home_preload_${node.id}_`}
              rel="preload"
              as="image"
              href={node.frontmatter.image.publicURL}
            />
          );
        })}
      </Helmet>
      <div className="owl-stage-outer">
        <div className="owl-stage">
          {edges.map(({ node }) => {
            return (
              <div
                key={node.id}
                className="owl-item position-relative overflow-hidden"
              >
                <div
                  className="background-image-wrapper position-absolute top-0 left-0 right-0 bottom-0"
                  data-appear-animation="kenBurnsToLeft"
                  data-appear-animation-duration="30s"
                  data-plugin-options="{'minWindowWidth': 0}"
                  data-carousel-onchange-show
                  style={{
                    backgroundImage: `url(${node.frontmatter.image.publicURL})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <div
                    className="elementor-background-overlay"
                    style={{
                      backgroundColor: "transparent",
                      backgroundImage:
                        "linear-gradient(-90deg, #fff 20%, rgba(242,242,242,0) 100%)",
                      opacity: "0.92",
                      transition:
                        "background 0.3s, border-radius 0.3s, opacity 0.3s",
                      height: "100%",
                      width: "100%",
                      top: 0,
                      left: 0,
                      position: "absolute",
                    }}
                  />
                </div>

                <div className="container position-relative z-index-3 h-100">
                  <div className="row align-items-center justify-content-end h-100">
                    <div className="col-lg-8 col-xl-7 text-center text-md-end">
                      {node.frontmatter.title_sm && (
                        <h2
                          className="font-weight-bold text-color-dark line-height-4 text-4 text-md-6 mb-2"
                          data-plugin-options="{'minWindowWidth': 0}"
                        >
                          {node.frontmatter.title_sm}
                        </h2>
                      )}
                      <h1
                        className="text-color-dark font-weight-bold text-9 text-md-11 line-height-2 mb-4"
                        data-plugin-options="{'minWindowWidth': 0}"
                      >
                        {node.frontmatter.title}
                      </h1>
                      {node.frontmatter.cta && (
                        <Link
                          to={slug(node.frontmatter.cta.url)}
                          className="btn btn-primary font-weight-bold positive-ls-3 btn-px-5 btn-py-3"
                          data-plugin-options="{'minWindowWidth': 0}"
                        >
                          {node.frontmatter.cta.label}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="owl-nav">
        <button type="button" role="presentation" className="owl-prev" />
        <button type="button" role="presentation" className="owl-next" />
      </div>
    </div>
  );
};

export default Home;
