import React from "react";
import { graphql } from "gatsby";

import { Page } from "engine/pageContext";

import Layout from "../components/layout";
import Home from "../components/home";
import Intro from "../components/intro";
import Grid from "../components/grid";
import Cases from "../components/cases";
import Steps from "../components/steps";
import Testimonials from "../components/testimonials";
import Contacts from "../components/contacts";
import Calculator from "../components/calculator";

const IndexPage = ({ pageContext: { lang }, data: { localize } }) => {
  return (
    <Page pageContext={{ lang, l: localize }}>
      <Layout>
        <div role="main" className="main">
          <Home />
          <Intro />
          <Calculator />
          <Grid />
          <Cases />
          <Steps />
          <Testimonials />
          <hr />
          <Contacts />
        </div>
      </Layout>
    </Page>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query ($lang: String!) {
    localize(lang: $lang)
  }
`;
