import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { useLocalize } from "engine/pageContext";

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { tags: { in: ["reviews"] } } }
        sort: { fields: [frontmatter___order], order: DESC }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              langKey
              order
              author
            }
          }
        }
      }
    }
  `);
  const { lang } = useLocalize();
  const edges = data.allMarkdownRemark.edges.filter(
    (x) => x.node.frontmatter.langKey === lang
  );

  return (
    <div className="container py-5 my-5">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div
            className="owl-carousel owl-theme nav-dark nav-style-1 nav-arrows-thin nav-font-size-lg nav-outside mb-0"
            data-plugin-options="{'responsive': {'0': {'items': 1, 'nav': false, 'dots': true}, '576': {'items': 1, 'nav': false, 'dots': true}, '768': {'items': 1}, '992': {'items': 1}, '1200': {'items': 1}}, 'loop': true, 'nav': true, 'dots': false, 'autoHeight': true, 'autoplay': true, 'autoplayHoverPause': true, 'autoplayTimeout': 7000, 'smartSpeed': 1000, 'slideTransition': 'ease-out'}"
          >
            {edges.map(({ node }) => {
              return (
                <div key={node.id}>
                  <div className="custom-testimonial-style-1 testimonial testimonial-style-2 testimonial-with-quotes testimonial-remove-right-quote testimonial-with-quotes-mobile-remove testimonial-quotes-dark mb-3 mb-md-0">
                    <blockquote className="px-0 px-md-3">
                      <div
                        className="alternative-font-4 text-color-dark text-4-5 line-height-9 px-0 px-md-4 mb-0"
                        dangerouslySetInnerHTML={{ __html: node.html }}
                      ></div>
                    </blockquote>
                    <div className="testimonial-author">
                      <cite className="text-color-dark fst-normal font-weight-extra-bold text-5-5">
                        {node.frontmatter.author}
                      </cite>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
