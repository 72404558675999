import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { useLocalize } from "engine/pageContext";

const Steps = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { tags: { in: ["steps"] } } }
        sort: { fields: [frontmatter___order], order: ASC }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              langKey
              order
              icon
            }
          }
        }
      }
    }
  `);

  const {
    lang,
    localize: {
      steps: { steps, why },
    },
  } = useLocalize();
  const edges = data.allMarkdownRemark.edges.filter(
    (x) => x.node.frontmatter.langKey === lang
  );

  return (
    <section className="section bg-color-grey-scale-1 border-0 m-0">
      <div className="container pt-5 pb-3">
        <div className="row">
          <div className="col text-center">
            <h3 className="font-weight-bold text-color-default line-height-1 text-4 ls-0 mb-1">
              {steps}
            </h3>
            <h2 className="text-color-dark font-weight-bold text-8">{why}</h2>
          </div>
        </div>
        <div className="row process custom-process justify-content-center mt-4">
          {edges.map(({ node }) => {
            return (
              <div
                key={`_step_${node.id}_`}
                className="process-step col-md-6 mb-5 mb-lg-4"
              >
                <div className="process-step-circle border-width-3 mb-3">
                  <strong className="process-step-circle-content text-color-primary">
                    {node.frontmatter.icon && (
                      <i className={`text-6  fas ${node.frontmatter.icon}`}></i>
                    )}
                  </strong>
                </div>
                <div className="process-step-content px-4">
                  {node.frontmatter.title && (
                    <h4 className="font-weight-bold text-6 pb-1 mb-2">
                      {node.frontmatter.title}
                    </h4>
                  )}
                  <div
                    className="text-3-5 text-color-dark mb-0"
                    dangerouslySetInnerHTML={{ __html: node.html }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Steps;
