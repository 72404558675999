import React, { useEffect, useState } from "react";

const minSalary = 22.8;
const maxSalary = 60;
const accomondation = 1.46;
const clothing = 0.16;
const medical = 0.17;
const communication = 0.5;

const numberFormat = new Intl.NumberFormat("pl-PL", {
  currency: "PLN",
  style: "currency",
});
const formatNumber = (num) => {
  return numberFormat.format(num);
};

const Calculator = () => {
  const [salary, setSalary] = useState(minSalary);
  const [zus, setZus] = useState(salary * 0.2);
  const [margin, setMargin] = useState(salary * 0.108);

  useEffect(() => {
    setZus(salary * 0.205);
    setMargin(salary * 0.108);
  }, [salary]);

  return (
    <section className="section overlay overlay-show overlay-color-primary custom-overlay-opacity-95 border-0 m-0">
      <div className="container position-relative z-index-2 pt-5">
        <div className="row ">
          <div className="col text-center">
            <h2 className="font-weight-bold text-color-light text-11 line-height-3 line-height-md-1 mb-5">
              Kalkulator
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-6 p-0">
            <div className="h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-right py-5">
                  <h3 className="font-weight-semibold text-color-light text-5 line-height-4 line-height-md-1 mb-3">
                    Ile zapłacisz za zatrudnienie nowego pracownika?
                  </h3>

                  <p className="text-color-light text-4 line-height-3 line-height-md-1 mb-3">
                    Nasz łatwy w obsłudze kalkulator pozwoli Ci to szybko
                    sprawdzić. Poznaj koszt inwestycji w nowy talent w kilka
                    sekund.
                  </p>

                  <h3 className="font-weight-semibold text-color-light text-5 line-height-4 line-height-md-1 mb-3">
                    Pełny cykl zatrudnienia
                  </h3>
                  <p className="text-color-light text-4 line-height-3 line-height-md-1 mb-3">
                    Nie tylko kalkulator! Nasza agencja oferuje pełny cykl
                    zatrudnienia, od A do Z, łącznie z formalnościami i
                    kosztami, takimi jak:
                  </p>
                  <ul className="text-color-light text-4 line-height-3 line-height-md-1 mb-3">
                    <li>legalizacja pracy</li>
                    <li>dojazd do pracy</li>
                    <li>wyposażenie pracownika</li>
                  </ul>
                  <h3 className="font-weight-semibold text-color-light text-5 line-height-4 line-height-md-1 mb-3">
                    Faktura VAT
                  </h3>

                  <p className="text-color-light text-4 line-height-3 line-height-md-1 mb-3">
                    Nasze usługi są objęte fakturą VAT, dzięki czemu masz pełną
                    kontrolę nad swoimi wydatkami.
                  </p>

                  <p className="text-color-light text-4 line-height-3 line-height-md-1 mb-3">
                    Nie czekaj! Skorzystaj z naszego kalkulatora teraz i miej
                    pewność, że podjąłeś najlepszą decyzję dla swojej firmy.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 p-0">
            <form className="calcualtor-form form-style-5">
              <div className="h-100 m-0">
                <div className="row m-0">
                  <div className="col-half-section col-half-section-left py-5">
                    <div className="form-group mb-1">
                      <label
                        htmlFor="salary"
                        className="form-label mb-3 text-4 text-color-light"
                      >
                        Wynagrodzenie godzinowe brutto:{" "}
                        <strong>{formatNumber(salary.toFixed(2))}</strong>
                      </label>
                      <input
                        id="salary"
                        type="range"
                        className="form-range mb-3"
                        value={salary}
                        onChange={(ev) => setSalary(Number(ev.target.value))}
                        min={minSalary}
                        max={maxSalary}
                        step={0.1}
                      />
                    </div>
                    <div className="form-group mb-1">
                      <label className="form-label text-4 text-color-light">
                        ZUS Pracodawcy:{" "}
                        <strong>{formatNumber(zus.toFixed(2))}</strong>
                      </label>
                    </div>
                    <hr style={{ background: "#fff" }} />
                    <div className="form-group mb-1">
                      <label className="form-label text-4 text-color-light">
                        Razem koszt zatrudnienia:{" "}
                        <strong>
                          {formatNumber((salary + zus).toFixed(2))}
                        </strong>
                      </label>
                    </div>
                    <hr style={{ background: "#fff" }} />
                    <div className="form-group mb-1">
                      <label className="form-label text-4 text-color-light">
                        Marża:{" "}
                        <strong>{formatNumber(margin.toFixed(2))}</strong>
                      </label>
                    </div>
                    <div className="form-group mb-1">
                      <label className="form-label text-4 text-color-light">
                        Zakwaterowanie:{" "}
                        <strong>
                          {formatNumber(accomondation.toFixed(2))}
                        </strong>
                      </label>
                    </div>
                    <div className="form-group mb-1">
                      <label className="form-label text-4 text-color-light">
                        Odzież i obuwie:{" "}
                        <strong>{formatNumber(clothing.toFixed(2))}</strong>
                      </label>
                    </div>
                    <div className="form-group mb-1">
                      <label className="form-label text-4 text-color-light">
                        Badania lekarskie:{" "}
                        <strong>{formatNumber(medical.toFixed(2))}</strong>
                      </label>
                    </div>
                    <div className="form-group mb-1">
                      <label className="form-label text-4 text-color-light">
                        Dojazd do pracy:{" "}
                        <strong>
                          {formatNumber(communication.toFixed(2))}
                        </strong>
                      </label>
                    </div>
                    <hr style={{ background: "#fff" }} />
                    <div className="form-group mb-1">
                      <label className="form-label text-4 text-color-light">
                        Razem do FV:{" "}
                        <strong>
                          {formatNumber(
                            (
                              salary +
                              zus +
                              margin +
                              accomondation +
                              clothing +
                              medical +
                              communication
                            ).toFixed(2)
                          )}
                        </strong>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calculator;
