import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

import { useLocalize } from "engine/pageContext";
import { Helmet } from "react-helmet";

const Grid = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { tags: { in: ["grid"] } } }
        sort: { fields: [frontmatter___order], order: ASC }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              title_sm
              title
              langKey
              order
              image {
                publicURL
              }
              alt
              url
            }
          }
        }
      }
    }
  `);

  const {
    lang,
    localize: { grid },
    slug,
  } = useLocalize();

  const edges = data.allMarkdownRemark.edges.filter(
    (x) => x.node.frontmatter.langKey === lang
  );
  const { btn } = grid;

  return (
    <div className="container-fluid">
      <Helmet>
        {edges.map(({ node }) => {
          return (
            <link
              key={`_grid_preload_${node.id}_`}
              rel="preload"
              as="image"
              href={node.frontmatter.image.publicURL}
            />
          );
        })}
      </Helmet>
      {edges.map(({ node }) => {
        const reverseOrder = parseInt(node.frontmatter.order) % 2 === 0;
        return (
          <div className="row" key={node.id}>
            <div
              className={`col-lg-6 p-0 order-2 ${
                reverseOrder ? "order-lg-2" : "order-lg-1"
              }`}
            >
              <section
                className="section h-100 m-0"
                style={{
                  minHeight: "315px",
                  backgroundImage: `url(${node.frontmatter.image.publicURL})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  border: "none",
                }}
              ></section>
            </div>
            <div
              className={`col-lg-6 p-0 order-1 ${
                reverseOrder ? "order-lg-1" : "order-lg-2"
              }`}
            >
              <div className="h-100 m-0">
                <div className="row m-0">
                  <div className="col-half-section col-half-section-left py-5">
                    <div className="p-3">
                      <h3
                        className="font-weight-semibold ls-0 text-color-default text-4 mb-0"
                        data-plugin-options="{'minWindowWidth': 0}"
                      >
                        {node.frontmatter.title_sm}
                      </h3>
                      <h2
                        className="font-weight-bold text-8 mb-4"
                        data-plugin-options="{'minWindowWidth': 0}"
                      >
                        {node.frontmatter.title}
                      </h2>
                      <div
                        className="text-3-5 pb-2 mb-4"
                        data-plugin-options="{'minWindowWidth': 0}"
                        dangerouslySetInnerHTML={{ __html: node.html }}
                      ></div>
                      <Link
                        to={slug(node.frontmatter.url)}
                        className="btn border rounded-0 px-5 btn-py-3 font-weight-bold positive-ls-2 text-color-dark bg-color-hover-primary text-color-hover-light"
                        data-plugin-options="{'minWindowWidth': 0}"
                      >
                        {btn}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Grid;
