import React from "react";

import { useLocalize } from "engine/pageContext";

const Intro = () => {
  const {
    localize: {
      intro: { title, description },
    },
  } = useLocalize();

  return (
    <div id="home-intro" className="home-intro bg-dark m-0">
      <div className="container py-2">
        <div className="row align-items-center text-center text-md-start">
          <div className="col-12 mb-3 mb-lg-0">
            <p className="text-color-light text-5-5 line-height-4 mb-0">
              {title}
              <span className="text-color-light opacity-8 font-weight-light text-3-5 px-4 px-md-0 mb-3 mb-lg-0">
                {description}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
