import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { useLocalize } from "engine/pageContext";

const Cases = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { frontmatter: { tags: { in: ["cases"] } } }) {
        edges {
          node {
            html
            frontmatter {
              title_sm
              title
              langKey
              year
              employees
              satisfied_clients
              mean_order_time
            }
          }
        }
      }
    }
  `);
  const {
    lang,
    localize: { cases: locale },
  } = useLocalize();
  const {
    node: {
      frontmatter: {
        title,
        title_sm,
        year,
        employees,
        satisfied_clients,
        mean_order_time,
      },
      html,
    },
  } = data.allMarkdownRemark.edges.find(
    (x) => x.node.frontmatter.langKey === lang
  );

  return (
    <section
      className="section overlay overlay-show overlay-color-primary custom-overlay-opacity-95 border-0 m-0"
      style={{
        background: "url(/img/demos/finance/backgrounds/background-1.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container position-relative z-index-2 pt-5">
        <div className="row">
          <div className="col text-center">
            <h3 className="font-weight-bold text-color-light text-4-5 ls-0 mb-2">
              {title_sm}
            </h3>
            <h2 className="font-weight-bold text-color-light text-11 line-height-3 line-height-md-1 mb-5">
              {title}
            </h2>
            <div
              className="text-color-light text-3-5 line-height-9"
              dangerouslySetInnerHTML={{ __html: html }}
            ></div>
          </div>
        </div>
        <div className="row counters counters-sm text-6 pb-5 pt-4 mt-5">
          <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
            <div className="counter">
              <strong
                className="text-color-light font-weight-extra-bold text-8 line-height-1"
                data-to={year}
                data-append="+"
              >
                0
              </strong>
              <span className="text-color-light font-weight-normal line-height-1 text-0 mt-0">
                {locale.year}
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0">
            <div className="counter">
              <strong
                className="text-color-light font-weight-extra-bold text-8 line-height-1"
                data-to={employees}
                data-append="+"
              >
                0
              </strong>
              <span className="text-color-light font-weight-normal line-height-1 text-0 mt-0">
                {locale.employees}
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mb-5 mb-sm-0">
            <div className="counter">
              <strong
                className="text-color-light font-weight-extra-bold text-8 line-height-1"
                data-to={satisfied_clients}
                data-append="%"
              >
                0
              </strong>
              <span className="text-color-light font-weight-normal line-height-1 text-0 mt-0">
                {locale.satisfied_clients}
              </span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="counter">
              <strong
                className="text-color-light font-weight-extra-bold text-8 line-height-1"
                data-to={mean_order_time}
                data-append="%"
              >
                0
              </strong>
              <span className="text-color-light font-weight-normal line-height-1 text-0 mt-0">
                {locale.mean_order_time}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cases;
